<template>
  <div>
    <v-speed-dial v-model="fab" direction="left" bottom right fixed>
      <template v-slot:activator>
        <v-btn v-model="fab" large color="accent" dark fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-file-document-edit</v-icon>
        </v-btn>
      </template>

      <!-- Password Reset Link -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              :disabled="!allTenantComes"
              @click="openSendPassword()"
              v-bind="attrs"
              v-on="on"
              fab
              :dark="allTenantComes"
              color="purple">
              <v-icon>mdi-lock-reset</v-icon>
            </v-btn>
          </div>
        </template>
        <span>Password Link Resend</span>
      </v-tooltip>

      <!-- Send Email Verify Link -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              :disabled="!allTenantComes || isVerified"
              @click="openSendVerify()"
              v-bind="attrs"
              v-on="on"
              fab
              :dark="!(!allTenantComes || isVerified)"
              color="blue">
              <v-icon>mdi-email-sync</v-icon>
            </v-btn>
          </div>
        </template>
        <span>Send Email Verify Link</span>
      </v-tooltip>

      <!-- Verify Account -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              :disabled="!allTenantComes || isVerified"
              @click="openDirectVerify()"
              v-bind="attrs"
              v-on="on"
              fab
              :dark="!(!allTenantComes || isVerified)"
              color="green">
              <v-icon>mdi-shield-check</v-icon>
            </v-btn>
          </div>
        </template>
        <span>Verify Account</span>
      </v-tooltip>

      <!-- Anonymize Account -->
      <v-tooltip top v-if="hasAccess">
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="openAnonymizeAccount()" fab color="blue-grey" v-bind="attrs" v-on="on" dark>
            <v-icon>mdi-incognito</v-icon>
          </v-btn>
        </template>
        <span>Anonymize Account</span>
      </v-tooltip>

      <!-- Download Personal Data -->
      <v-tooltip top v-if="hasAccess">
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="downloadPersonalData()" fab color="indigo" v-bind="attrs" v-on="on" dark>
            <v-icon>mdi-account-arrow-down</v-icon>
          </v-btn>
        </template>
        <span>Download Personal Data</span>
      </v-tooltip>

      <!-- Send Verify Link -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn v-if="!isDeleted" @click="deleteAccount()" fab dark color="red">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn v-if="isDeleted" @click="deleteAccount()" fab dark color="success">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
        </template>
        <span v-show="!isDeleted">Delete Account</span>
        <span v-show="isDeleted">Undelete Account</span>
      </v-tooltip>
    </v-speed-dial>

    <v-dialog v-model="sendVerify" max-width=" 600px">
      <v-card>
        <v-flex class="mb-12"></v-flex>
        <base-material-card color="warning" icon="mdi-alert-circle-outline">
          <v-card-title class="text-h5" style="justify-content: center">
            Select tenant for the send verify link</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="d-flex flex-column p-4" style="gap: 2rem; width: 100%; justify-content: center; padding: 1rem">
              <div class="d-flex" style="align-items: center; gap: 10px">
                <SelectTenant :tenants="tenants" :allTenants="allTenants" v-on:onSelect="selectTenant($event)" />
                <v-select
                  v-model="selectedLanguage"
                  :items="language"
                  item-value="value"
                  item-text="label"
                  persistent-hint
                  label="Select language"></v-select>
              </div>
            </div>
            <v-spacer></v-spacer>
          </v-card-actions>
          <template v-slot:actions>
            <v-btn color="red" outlined @click="closeSendVerify"> Not Now </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="sendVerifyLink">
              <span v-show="!loading"> Send Link </span>
              <div v-show="loading" class="mng-loader"></div>
            </v-btn>
          </template>
        </base-material-card>
      </v-card>
    </v-dialog>

    <v-dialog v-model="directVerify" max-width=" 500px">
      <v-card>
        <v-flex class="mb-12"></v-flex>
        <base-material-card color="warning" icon="mdi-alert-circle-outline">
          <v-card-title class="text-h5" style="justify-content: center">
            Do you want to verify this account?
          </v-card-title>
          <template v-slot:actions>
            <v-btn color="red" outlined @click="closeDirectVerify"> Not Now </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="sendDirectVerify">
              <span v-show="!loading">Verify </span>
              <div v-show="loading" class="mng-loader"></div>
            </v-btn>
          </template>
        </base-material-card>
      </v-card>
    </v-dialog>

    <v-dialog v-model="anonymizeAccountDialog" max-width=" 700px">
      <v-card>
        <v-flex class="mb-12"></v-flex>
        <base-material-card color="warning" icon="mdi-alert-circle-outline">
          <template v-slot:after-heading>
            <div class="ml-auto body-3">
              Anonymize Account
              <h3>Are you sure that you want to anonymize this account?</h3>
            </div>
          </template>
          <v-divider class="mt-2" />
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="d-flex flex-column p-4" style="width: 100%; justify-content: center; padding: 1rem">
              <p class="subtitle-1 font-weight-bold secondary--text mb-0">
                The orders of this account will also be anonymized. If you don’t want to anonymize the orders, please
                tick the box below.
              </p>
              <v-checkbox v-model="doNotAnonymizeOrders" label="Do not anonymize orders"></v-checkbox>
            </div>
            <v-spacer></v-spacer>
          </v-card-actions>
          <template v-slot:actions>
            <v-btn color="red" outlined @click="anonymizeAccountDialog = false" large>Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="anonymizeAccount" color="success" large>
              <span v-show="!loading">Confirm</span>
              <div v-show="loading" class="mng-loader"></div>
            </v-btn>
          </template>
        </base-material-card>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sendPassword" max-width=" 600px">
      <v-card>
        <v-flex class="mb-12"></v-flex>
        <base-material-card color="warning" icon="mdi-alert-circle-outline">
          <v-card-title class="text-h5" style="justify-content: center">
            Select tenant for the send reset password link</v-card-title
          >
          <v-divider class="mt-2" />
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="d-flex flex-column p-4" style="gap: 2rem; width: 100%; justify-content: center; padding: 1rem">
              <div class="d-flex" style="align-items: center; gap: 10px">
                <SelectTenant :tenants="tenants" :allTenants="allTenants" v-on:onSelect="selectTenant($event)" />
                <v-select
                  v-model="selectedLanguage"
                  :items="language"
                  item-value="value"
                  item-text="label"
                  persistent-hint
                  label="Select language"></v-select>
              </div>
            </div>
            <v-spacer></v-spacer>
          </v-card-actions>
          <template v-slot:actions>
            <v-btn color="red" outlined @click="sendPassword = false" x-large>Not Now </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="sendResetPassword" color="success" x-large>
              <span v-show="!loading">Send Password Link </span>
              <div v-show="loading" class="mng-loader"></div>
            </v-btn>
          </template>
        </base-material-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import SelectTenant from "../../../util/shared-components/select-tenant.vue";
import AccountApi from "@/services/account.api";

export default {
  name: "FloatButtons",
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectTenant,
  },
  props: {
    tenants: "",
    isDeleted: false,
    loading: false,
    isVerified: false,
    isSendVerify: "",
    isSendPassword: "",
    isDirectVerify: "",
    isAnonymize: "",
  },
  emit: [
    "sendVerify",
    "openSendVerify",
    "closeSendVerify",
    "sendDirectVerify",
    "openDirectVerify",
    "closeDirectVerifyy",
    "sendResetPassword",
    "openSendPassword",
    "closeSendPassword",
    "delete",
    "anonymizeAccount",
    "openAnonymizeAccount",
    "closeAnonymizeAccount",
    "downloadPersonalData",
  ],
  data() {
    return {
      selectedTenant: "",
      language: [
        { label: "Swedish", value: "sv-SE" },
        { label: "Norwegian", value: "nb-NO" },
        { label: "English", value: "en-SE" },
      ],
      selectedLanguage: "en-SE",
      fab: false,
      tempTenant: [],
      allTenantComes: false,
      sendVerify: "",
      sendPassword: "",
      directVerify: "",
      anonymizeAccountDialog: false,
      doNotAnonymizeOrders: false,
      token: null,
    };
  },
  computed: {
    ...mapGetters({
      allTenants: "auth/userTenants",
      userRoles: "auth/userRoles",
      user: "auth/user",
    }),
    AccountModel() {
      return new AccountApi(this.tenant, this.token);
    },
    hasAccess() {
      return ["Role.Admin", "Role.Admin.BackOffice", "Role.Account.Manager"].some((role) =>
        this.userRoles.includes(role)
      );
    },
  },
  methods: {
    selectTenant(event) {
      if (typeof event == "object") {
        this.selectedTenant = event.id;
      } else {
        this.selectedTenant = event;
      }
    },
    //Send Verify
    sendVerifyLink() {
      if (this.selectedTenant) {
        this.$emit("sendVerify", { tenant: this.selectedTenant, language: this.selectedLanguage });
      } else {
        this.$store.dispatch("error", "Please select tenant first");
      }
    },
    anonymizeAccount() {
      this.$emit("anonymizeAccount", this.doNotAnonymizeOrders);
    },
    openAnonymizeAccount() {
      this.$emit("openAnonymizeAccount");
    },
    closeAnonymizeAccount() {
      this.$emit("closeAnonymizeAccount");
    },
    openSendVerify() {
      this.$emit("openSendVerify");
    },
    closeSendVerify() {
      this.$emit("closeSendVerify");
    },
    sendDirectVerify() {
      this.$emit("sendDirectVerify");
    },
    openDirectVerify() {
      this.$emit("openDirectVerify");
    },
    closeDirectVerify() {
      this.$emit("closeDirectVerify");
    },
    //Send Password
    sendResetPassword() {
      if (this.selectedTenant) {
        this.$emit("sendResetPassword", { tenant: this.selectedTenant, language: this.selectedLanguage });
      } else {
        this.$store.dispatch("error", "Please select tenant first");
      }
    },
    openSendPassword() {
      this.$emit("openSendPassword");
    },
    closeSendPassword() {
      this.$emit("closeSendPassword");
    },
    deleteAccount() {
      this.$emit("delete");
    },
    downloadPersonalData() {
      this.$emit("downloadPersonalData");
    },
  },
  watch: {
    allTenants: {
      deep: true,
      handler: function f(val) {
        if (this.allTenants.length > 0) {
          this.allTenantComes = true;
        }
      },
    },
    isSendVerify: {
      deep: true,
      handler: function f(val) {
        this.sendVerify = val;
      },
    },
    sendVerify: {
      deep: true,
      handler: function f(val) {
        if (!val) {
          this.closeSendVerify();
        }
      },
    },
    isDirectVerify: {
      deep: true,
      handler: function f(val) {
        this.directVerify = val;
      },
    },
    directVerify: {
      deep: true,
      handler: function f(val) {
        if (!val) {
          this.closeDirectVerify();
        }
      },
    },
    isSendPassword: {
      deep: true,
      handler: function f(val) {
        this.sendPassword = val;
      },
    },
    sendPassword: {
      deep: true,
      handler: function f(val) {
        if (!val) {
          this.closeSendPassword();
        }
      },
    },
    isAnonymize: {
      deep: true,
      handler: function f(val) {
        this.anonymizeAccountDialog = val;
      },
    },
    anonymizeAccountDialog: {
      deep: true,
      handler: function f(val) {
        if (!val) {
          this.closeAnonymizeAccount();
          this.doNotAnonymizeOrders = false;
        }
      },
    },
  },
};
</script>
<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
</style>
