var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "account-detail"
  }, [_c('header', {
    staticClass: "theme--light v-navigation-drawer elevation-1",
    staticStyle: {
      "height": "50px",
      "border-radius": "5px",
      "margin-bottom": "10px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__content flex flex-row justify-space-between",
    staticStyle: {
      "height": "50px",
      "padding": "25px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__title"
  }, [_c('h4', {
    staticClass: "black--text"
  }, [_vm._v("Account Detail")])]), _c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "gap": "20px"
    }
  }, [_c('div', {
    staticClass: "d-flex lock",
    staticStyle: {
      "gap": "20px"
    }
  }, [_c('h3', [_vm._v("Account Verified")]), _vm.account.isVerified ? _c('img', {
    attrs: {
      "src": require("../../../assets/accept.png"),
      "alt": ""
    }
  }) : _vm._e(), !_vm.account.isVerified ? _c('img', {
    attrs: {
      "src": require("../../../assets/cancel.png"),
      "alt": ""
    }
  }) : _vm._e(), _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "color": "black"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-help-circle-outline")])];
      }
    }])
  }, [_c('span', [_vm._v("Accounts have to verify their e-mail addresses to be able to log in.")])])], 1), _c('div', {
    staticClass: "d-flex lock",
    staticStyle: {
      "gap": "20px"
    }
  }, [_vm.account.isDeleted ? _c('h3', {
    attrs: {
      "alt": ""
    }
  }, [_vm._v("Account Deleted")]) : _vm._e(), !_vm.account.isDeleted ? _c('h3', {
    attrs: {
      "alt": ""
    }
  }, [_vm._v("Account Active")]) : _vm._e(), !_vm.account.isDeleted ? _c('img', {
    attrs: {
      "src": require("../../../assets/accept.png"),
      "alt": ""
    }
  }) : _vm._e(), _vm.account.isDeleted ? _c('img', {
    attrs: {
      "src": require("../../../assets/cancel.png"),
      "alt": ""
    }
  }) : _vm._e()]), _c('v-btn', {
    staticStyle: {
      "height": "40px",
      "font-size": "13px"
    },
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.isUpdate = !_vm.isUpdate;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-3"
  }, [_vm._v("mdi-refresh")]), _vm._v(" Update Account")], 1)], 1)])]), _c('AccountUpdate', {
    attrs: {
      "account": _vm.account,
      "isUpdate": _vm.isUpdate
    },
    on: {
      "success": function ($event) {
        _vm.getToken();
        _vm.isUpdate = false;
      }
    }
  }), _c('passengerlist', {
    attrs: {
      "haveActions": true,
      "tenant": _vm.tenant,
      "token": _vm.token,
      "accountId": _vm.account.id,
      "loading": _vm.loading,
      "passenger": _vm.account.savedPassengers
    },
    on: {
      "change": _vm.getToken
    }
  }), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('GlobalCompanyList', {
    attrs: {
      "isDeleted": _vm.account.isDeleted,
      "connectedCustomers": _vm.customer,
      "loading": _vm.loading,
      "accountId": _vm.accountId,
      "propCompanies": _vm.customerFilter(_vm.account.companyCustomers)
    },
    on: {
      "refresh": _vm.getToken
    }
  })], 1)], 1), _c('float-buttons', {
    attrs: {
      "isDeleted": _vm.account.isDeleted,
      "isVerified": _vm.account.isVerified,
      "isSendVerify": _vm.isSendVerify,
      "isAnonymize": _vm.isAnonymize,
      "isSendPassword": _vm.isSendPassword,
      "isDirectVerify": _vm.isDirectVerify,
      "loading": _vm.loading,
      "tenants": _vm.account.tenants,
      "anonymized": _vm.account.anonymized
    },
    on: {
      "sendVerify": function ($event) {
        return _vm.sendVerifyLink($event);
      },
      "openSendVerify": function ($event) {
        _vm.isSendVerify = true;
      },
      "closeSendVerify": function ($event) {
        _vm.isSendVerify = false;
      },
      "sendDirectVerify": _vm.sendDirectVerify,
      "openDirectVerify": function ($event) {
        _vm.isDirectVerify = true;
      },
      "closeDirectVerify": function ($event) {
        _vm.isDirectVerify = false;
      },
      "closeSendPassword": function ($event) {
        _vm.isSendPassword = false;
      },
      "openSendPassword": function ($event) {
        _vm.isSendPassword = true;
      },
      "sendResetPassword": function ($event) {
        return _vm.sendPassword($event);
      },
      "delete": function ($event) {
        _vm.dialogDeleteAccount = true;
      },
      "openAnonymizeAccount": function ($event) {
        _vm.isAnonymize = true;
      },
      "closeAnonymizeAccount": function ($event) {
        _vm.isAnonymize = false;
      },
      "anonymizeAccount": _vm.anonymizeAccount,
      "downloadPersonalData": _vm.downloadPersonalData
    }
  }), _c('v-dialog', {
    staticStyle: {
      "padding": "1rem"
    },
    attrs: {
      "max-width": "600px"
    },
    model: {
      value: _vm.dialogDeleteAccount,
      callback: function ($$v) {
        _vm.dialogDeleteAccount = $$v;
      },
      expression: "dialogDeleteAccount"
    }
  }, [_c('v-card', [!_vm.account.isDeleted ? _c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v("Are you sure you want to delete this account?")]) : _vm._e(), _vm.account.isDeleted ? _c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v("Are you sure you want to undelete this account?")]) : _vm._e(), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.dialogDeleteAccount = false;
      }
    }
  }, [_vm._v("Cancel ")]), !_vm.account.isDeleted ? _c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.deleteAccount
    }
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading,
      expression: "!loading"
    }]
  }, [_vm._v(" Delete ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "mng-loader"
  })]) : _vm._e(), _vm.account.isDeleted ? _c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.deleteAccount
    }
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading,
      expression: "!loading"
    }]
  }, [_vm._v(" Undelete ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "mng-loader"
  })]) : _vm._e(), _c('v-spacer')], 1)], 1)], 1), _c('header', {
    staticClass: "theme--light v-navigation-drawer elevation-1 mt-4 mb-4",
    staticStyle: {
      "height": "50px",
      "border-radius": "5px",
      "margin-bottom": "10px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__content flex flex-row justify-space-between",
    staticStyle: {
      "height": "50px",
      "padding": "25px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__title"
  }, [_c('h4', {
    staticClass: "black--text"
  }, [_vm._v("Account Logs")])])])]), _vm.events.length > 0 ? _c('div', [_c('v-card-text', {
    staticClass: "elevation-1"
  }, [_c('v-timeline', {
    attrs: {
      "dense": "",
      "align-top": ""
    }
  }, [_c('event-list', {
    attrs: {
      "events": _vm.events
    }
  })], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }